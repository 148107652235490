import * as React from 'react';
import {
  useFilters,
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useExpanded,
  useAsyncDebounce,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { TableProps, Filter, TableRows } from './types';
import {
  TableStyled,
  TableStyledWithBoxShadow,
  Row,
  AlternatingRow,
  TdStyled,
  EmptyResult,
  TableActionBar,
  HeaderSortingWrapper,
} from './style';
import { defaultTheme, Theme, RenderWithTheme } from '../theme/theme';

import {
  ChevronDown,
  ChevronUp,
  Plus,
  Sort,
  SortAscending,
  SortDescending,
} from '../icons/icons';
import { TooltipLight } from '../tooltip/tooltip';
import { FilterBar } from './filter-bar';
import { Pagination } from './pagination';
import {
  arrayToObject,
  parseQuery,
  stringifyPath,
  stringifySort,
  parseSort,
} from './url';
import { ColumnDefinition } from './types';
import { BooleanFilterBar } from './boolean-filter-bar';
import {
  formatCurrency,
  formatBoolean,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatString,
} from '../formatters';
import { CheckboxNew } from '../form/checkbox';
import get from 'lodash.get';

export {
  TableStyled,
  TableStyledWithBoxShadow,
  Row as TableRow,
  AlternatingRow as TableAlternatingRow,
  TdStyled,
  EmptyResult as TableEmptyResult,
} from './style';

export type { TableProps };

export function Table<T>({
  columns,
  data,
  withFilter,
  filterKind,
  filterState,
  pageSize: controlledPageSize = 15,
  pageNumber = 0,
  total,
  isLoading,
  expanded,
  multiSelect,
  withAlternatingRows,
  withBoxShadow,
  withPagination,
  fetchData,
  withRouting,
  withExpandableRow,
  withTooltip = true,
  hideCellsOnExpand,
  compact,
  initialState = {},
  rowLink,
  openInNewTab,
  renderTableActions,
  onExpandedChange,
  onSelect,
  onRowClick,
  header,
  className,
  withBooleanFilter,
  canAddRows,
  canAddColumns,
  onAddColumn,
  onAddRow,
  addRowMessage = 'Neue Zeile hinzufügen',
  addColumnMessage = '',
  onPageChange,
  disabled,
}: TableProps<T>) {
  const history = useHistory();
  const theme = useTheme();
  const { search, pathname } = history?.location || {};
  const params = parseQuery(search || '');

  const hasBoxShadow = withBoxShadow || withFilter || renderTableActions;

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      filter: 'fuzzyText',
    }),
    []
  );

  const modifiedColumns = React.useMemo(() => {
    let modifiedColumns: Array<ColumnDefinition<T>> = [...columns];

    if (multiSelect) {
      modifiedColumns = [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const { onChange } = getToggleAllRowsSelectedProps() ?? {};

            return (
              <CheckboxNew
                id={`${filterKind}-checkbox-all`}
                disabled={disabled}
                {...getToggleAllRowsSelectedProps()}
                onClick={(e) => e.stopPropagation()}
                onChange={(_, e) => onChange(e)}
              />
            );
          },
          Cell: ({ row }) => {
            const { onChange } = row.getToggleRowSelectedProps() ?? {};
            return (
              <CheckboxNew
                id={`${filterKind}-checkbox-${row.id}`}
                disabled={disabled}
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => e.stopPropagation()}
                onChange={(_, e) => onChange(e)}
              />
            );
          },
          width: 30,
          filterable: false,
          disableSortBy: true,
        },
        ...columns,
      ];
    }

    return modifiedColumns.map(
      ({ width, maxWidth, minWidth, type, Cell, sortType, ...rest }) => {
        return {
          __width: width,
          __maxWidth: maxWidth,
          __minWidth: minWidth,
          Cell: Cell
            ? Cell
            : ({ value }: { value: any }) => {
                if (type === 'boolean') {
                  return formatBoolean(value);
                }
                if (type === 'currency') {
                  return formatCurrency(value);
                }
                if (type === 'date') {
                  return formatDate(value);
                }
                if (type === 'datetime') {
                  return formatDateTime(value);
                }
                if (type === 'decimal') {
                  return formatDecimal(value);
                }
                if (type === 'string') {
                  return formatString(value);
                }

                return value;
              },
          sortType: sortType
            ? sortType
            : (rowA: any, rowB: any, columnId: string) => {
                if (type === 'boolean') {
                  return String(rowA.values[columnId]).localeCompare(
                    String(rowB.values[columnId])
                  );
                }
                if (type === 'currency' || type === 'decimal') {
                  return (
                    Number(rowA.values[columnId]) -
                    Number(rowB.values[columnId])
                  );
                }
                if (type === 'date' || type === 'datetime') {
                  const dateA = new Date(rowA.values[columnId]);
                  const dateB = new Date(rowB.values[columnId]);
                  if (dateA < dateB) {
                    return -1;
                  }
                  if (dateA > dateB) {
                    return 1;
                  }
                  return 0;
                }
                return String(rowA.values[columnId]).localeCompare(
                  String(rowB.values[columnId])
                );
              },
          disableFilters: rest.filterable === false,
          ...rest,
        };
      }
    );
  }, [columns, multiSelect, filterKind, disabled]);

  const getFilterParams = React.useCallback(() => {
    if (!withRouting) return [];
    const filterOptions = modifiedColumns.reduce((acc, curr) => {
      const accessor = typeof curr.accessor === 'string' ? curr.accessor : '';
      return !curr.disableFilters && accessor ? [...acc, accessor] : acc;
    }, [] as string[]);

    return Object.keys(params).reduce(
      (acc, key) =>
        filterOptions.includes(key)
          ? [
              ...acc,
              {
                id: key,
                value: get(params, key),
              },
            ]
          : acc,
      [] as Filter[]
    );
  }, [withRouting, modifiedColumns, params]);

  const handleRowClick = (rowId: any) => {
    if (onExpandedChange && !disabled) onExpandedChange(state.expanded, rowId);
  };

  function renderRow(row: TableRows<any>) {
    prepareRow(row);
    const rowProps = row.getRowProps();
    const {
      cells,
      index,
      isSelected,
      isExpanded,
      getToggleRowExpandedProps,
      original,
    } = row;
    const clickCallback = () => handleRowClick(index);

    if (rowLink) {
      const to = rowLink({ row });

      if (to) {
        rowProps.onClick = () => {
          if (openInNewTab) {
            window.open(to, '_blank');
          } else {
            history.push(to);
          }
        };

        rowProps.style = {
          cursor: 'pointer',
        };
      }
    }

    if (onRowClick) {
      rowProps.onClick = () => {
        if (!disabled) onRowClick(row);
      };

      rowProps.style = {
        cursor: 'pointer',
      };
    }

    if (hasBoxShadow && !header)
      rowProps.className = [...(rowProps.className || []), 'noBorder'];

    if (isSelected || original.isSelected)
      rowProps.className = [...(rowProps.className || []), 'selected'];

    const RowWrapper = withAlternatingRows ? AlternatingRow : Row;

    let cellsToRender = cells.filter((c) => !c.column.hidden);
    if (hideCellsOnExpand) {
      let lastColSpan = -1;
      let lastIndexColSpan = -1;

      cellsToRender = cells.filter((cell: any, index: number) => {
        if (lastIndexColSpan !== -1 && lastIndexColSpan !== -1) {
          return index >= lastIndexColSpan + lastColSpan;
        }
        if (cell.row.original.colSpan) {
          lastIndexColSpan = index;
          lastColSpan = cell.row.original.colSpan;
        }
        return true;
      });
    }

    return (
      <RowWrapper {...rowProps}>
        {cellsToRender.map((cell: any, cellIndex: number) => {
          const cellProps = cell.getCellProps({
            colSpan: cell.row.original.colSpan,
            style: {
              ...(cell.column.style || {}),
              ...(withExpandableRow ? { verticalAlign: 'top' } : {}),
              ...(!(multiSelect && cell.column.id === 'selection')
                ? { paddingLeft: (row.depth + 1) * (compact ? 8 : 12) }
                : {}),
            },
          });

          if (compact)
            cellProps.className = `${cellProps.className || ''}, compact`;

          if (multiSelect && cell.column.id === 'selection')
            cellProps.className = `${cellProps.className || ''}, selection`;

          if (
            cellIndex === cellsToRender.length - 1 &&
            (withExpandableRow || cell.row.canExpand)
          ) {
            const ExpandIcon = isExpanded ? ChevronUp : ChevronDown;

            return (
              <TooltipLight
                id={`${index}-${cellIndex}`}
                effect="float"
                place="bottom"
                multiline
                text={cell.value}
                className="tooltip"
                visible={!!withTooltip && !row.isExpanded}
                key={`${index}-${cellIndex}`}
              >
                <TdStyled
                  {...cellProps}
                  data-tip
                  data-for={`${index}-${cellIndex}`}
                >
                  <span className="content-wrapper">
                    {cell.render('Cell')}
                    <span
                      onClick={clickCallback}
                      role="button"
                      tabIndex={0}
                      onKeyDown={clickCallback}
                    >
                      <ExpandIcon
                        {...getToggleRowExpandedProps()}
                        size={24}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  </span>
                </TdStyled>
              </TooltipLight>
            );
          } else {
            return <td {...cellProps}>{cell.render('Cell')}</td>;
          }
        })}
      </RowWrapper>
    );
  }

  function renderLoadingRows(pageSize: number, columns: any[]) {
    return Array(pageSize)
      .fill(0)
      .map((_: any, index: number) => (
        <Row key={index}>
          {columns.map((_: any, y: number) => (
            <td key={`${index}${y}`}>
              <ContentLoader
                id="content-loader-table"
                height={21}
                speed={2}
                style={{ width: '100%' }}
              >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="21" />
              </ContentLoader>
            </td>
          ))}
        </Row>
      ));
  }

  function renderSorted(column: any) {
    const style = { verticalAlign: 'bottom' };

    return (
      <RenderWithTheme>
        {(theme: Theme = defaultTheme) =>
          column.Header !== '' && !column.disableSortBy ? (
            column.isSorted ? (
              column.isSortedDesc ? (
                <SortDescending
                  size="20"
                  color={theme.primaryColor}
                  style={style}
                />
              ) : (
                <SortAscending
                  size="20"
                  color={theme.primaryColor}
                  style={style}
                />
              )
            ) : (
              <Sort size="20" style={style} />
            )
          ) : null
        }
      </RenderWithTheme>
    );
  }

  function renderHeader(column: any) {
    if (column.hidden) return null;

    return (
      <th
        {...column.getHeaderProps()}
        {...column.getHeaderProps(column.getSortByToggleProps())}
        onClick={(e) => {
          if (column.getHeaderProps(column.getSortByToggleProps()).onClick)
            column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
          if (column.onHeaderClick) column.onHeaderClick(column);
        }}
        style={{
          ...(column.__width && { width: column.__width }),
          ...(column.__minWidth && { width: column.__minWidth }),
          ...(column.__maxWidth && { maxWidth: column.__maxWidth }),
        }}
        className={`
          ${column.disableSortBy ? '' : 'canSort'}
          ${column.isSorted ? 'sorted' : ''}
          ${hasBoxShadow && !header ? 'noBorder' : ''}
          ${compact ? 'compact' : ''}
          ${multiSelect && column.id === 'selection' ? 'selection' : ''}
        `}
      >
        {column.Header !== '' && !column.disableSortBy ? (
          <HeaderSortingWrapper>
            {column.render('Header')}
            {column.id !== 'selection' && renderSorted(column)}
          </HeaderSortingWrapper>
        ) : (
          column.render('Header')
        )}
      </th>
    );
  }

  const serverSideActions = React.useMemo(() => !!fetchData, [fetchData]);

  const tableFilters = React.useMemo(() => {
    const paramsState = getFilterParams();
    if (paramsState && paramsState.length > 0) {
      return paramsState;
    }
    if (filterState) {
      return filterState;
    }
    return undefined;
  }, [filterState, getFilterParams]);

  const {
    headerGroups,
    getTableProps,
    setAllFilters,
    setFilter,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, sortBy, filters, selectedRowIds, ...state },
    page,
    pageCount,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
  } = useTable(
    {
      columns: modifiedColumns,
      data,
      filterTypes,
      defaultColumn,
      initialState: {
        pageSize: controlledPageSize,
        expanded: expanded ?? {},
        ...(tableFilters && { filters: tableFilters }),
        ...(withRouting && params.sort && { sortBy: parseSort(params.sort) }),
        ...initialState,
        ...(withPagination &&
          (serverSideActions || onPageChange) && {
            pageIndex: withRouting && params.page ? params.page : pageNumber,
          }),
      },
      manualPagination: (serverSideActions || onPageChange) && withPagination,
      manualSortBy: serverSideActions,
      manualFilters: serverSideActions,

      ...((serverSideActions || onPageChange) &&
        withPagination && {
          pageCount: total ? Math.ceil(total / controlledPageSize) : 0,
        }),
      expanded,
      onExpandedChange,

      autoResetFilters: !serverSideActions,
      autoResetPage: !((serverSideActions || onPageChange) && withPagination),
      autoResetSortBy: !serverSideActions,

      useControlledState: (controlledState: any) => {
        return React.useMemo(
          () => ({
            ...controlledState,
            ...(expanded && { expanded }),
          }),
          [controlledState]
        );
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    ...(withPagination ? [usePagination] : []),
    useRowSelect
  );

  const onFetchDataDebounced = useAsyncDebounce(fetchData, 100);

  React.useEffect(() => {
    if (serverSideActions) {
      gotoPage(pageNumber);
    }
  }, [serverSideActions, pageNumber, gotoPage]);

  React.useEffect(() => {
    if (serverSideActions) {
      onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters });
    }
  }, [
    serverSideActions,
    onFetchDataDebounced,
    filters,
    pageIndex,
    pageSize,
    sortBy,
  ]);

  React.useEffect(() => {
    if (filterState && !disabled) {
      // only apply the filters where we have columns for
      const filteredFilters = filterState.filter((f: Filter) =>
        modifiedColumns
          .filter((c) => c.filterable !== false)
          .find((c: ColumnDefinition) => c.id === f.id || c.accessor === f.id)
      );

      setAllFilters(filteredFilters);
    }
  }, [filterState, modifiedColumns, setAllFilters, disabled]);

  React.useEffect(() => {
    if (onSelect && !disabled) {
      onSelect(Object.keys(selectedRowIds));
    }
  }, [selectedRowIds, onSelect, disabled]);

  React.useEffect(() => {
    if (onPageChange && !disabled) {
      onPageChange(pageIndex);
    }
  }, [onPageChange, pageIndex, disabled]);

  /**
   * Update query params
   */
  React.useEffect(() => {
    if (!withRouting) {
      return;
    }

    let nextParams = params;

    // store filter in query params
    const filterObj = arrayToObject(filters, 'id', 'value');
    columns.forEach(({ accessor }) => {
      if (
        !filterObj.hasOwnProperty(accessor as string) &&
        params.hasOwnProperty(accessor as string)
      ) {
        delete nextParams[accessor as string];
      }
    });
    nextParams = { ...nextParams, ...filterObj };

    // store sort order in query params
    const isSorted = Object.keys(sortBy).length !== 0;
    const sortStr = stringifySort(sortBy);

    if (!isSorted && params.hasOwnProperty('sort')) {
      const { sort, ...rest } = nextParams;
      nextParams = { ...rest };
    } else if (isSorted && sortStr !== params.sort) {
      nextParams = { ...nextParams, sort: sortStr };
    }

    // store pageIndex in query params
    if (pageIndex === 0 && params.hasOwnProperty('page')) {
      const { page, ...rest } = nextParams;
      nextParams = { ...rest };
    } else if (
      pageIndex &&
      pageIndex !== 0 &&
      (params.page === undefined || parseInt(params.page, 10) !== pageIndex)
    ) {
      nextParams = { ...nextParams, page: pageIndex };
    }

    /**
     * only do a replacement if they differ
     */
    const nextPath = stringifyPath(pathname, nextParams);
    const oldPath = stringifyPath(pathname, params);
    if (nextPath !== oldPath) {
      history.replace(nextPath);
    }
  }, [
    sortBy,
    filters,
    pageIndex,
    withRouting,
    params,
    columns,
    pathname,
    history,
  ]);

  const TableWrapper = hasBoxShadow ? TableStyledWithBoxShadow : TableStyled;

  const filterBarFilters: Filter[] = withBooleanFilter
    ? filters.filter(
        (filter: Filter) =>
          !withBooleanFilter.find(
            (booleanFilter) => booleanFilter.id === filter.id
          )
      )
    : filters;

  return (
    <>
      <TableWrapper
        $disabled={disabled}
        className={`${className} ${rowLink ? 'hasLink' : ''}`}
      >
        {withFilter && filterKind && (
          <FilterBar
            filters={filterBarFilters}
            columns={columns}
            kind={filterKind}
            setAllFilters={setAllFilters}
          />
        )}
        {withBooleanFilter && (
          <BooleanFilterBar
            filters={withBooleanFilter}
            state={filters}
            setFilter={setFilter}
            setAllFilters={setAllFilters}
          />
        )}
        {renderTableActions ? (
          <TableActionBar>{renderTableActions()}</TableActionBar>
        ) : null}
        <div className={`wrapper ${header ? 'withHeader' : ''}`}>
          {header}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) =>
                    renderHeader(column)
                  )}
                  {canAddColumns && (
                    <th className="newColumn">
                      <button type="button" onClick={onAddColumn}>
                        <Plus size={28} color={theme.primaryColor} />{' '}
                        {addColumnMessage}
                      </button>
                    </th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading
                ? renderLoadingRows(3, modifiedColumns)
                : (withPagination ? page : rows).length > 0
                ? (withPagination ? page : rows).map(renderRow)
                : null}

              {canAddRows && (
                <tr>
                  <td
                    className="newRow"
                    colSpan={Math.max(
                      1,
                      ...(headerGroups.map(
                        (headerGroup: any) => headerGroup?.headers?.length ?? 0
                      ) ?? [])
                    )}
                  >
                    <button type="button" onClick={onAddRow}>
                      <Plus size={28} color={theme.primaryColor} />{' '}
                      {addRowMessage}
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!isLoading && (withPagination ? page : rows).length === 0 && (
            <EmptyResult>Keine {filterKind} gefunden.</EmptyResult>
          )}
        </div>
      </TableWrapper>
      {withPagination && (
        <Pagination
          nextPage={nextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          page={page}
          rowCount={total ?? rows.length}
        />
      )}
    </>
  );
}

function fuzzyTextFilterFn(rows: Array<any>, id: string, filterValue: string) {
  return matchSorter(rows, filterValue, {
    threshold: matchSorter.rankings.CONTAINS,
    keys: [(row) => row.values[id]],
  });
}

// if we pass null to reset the filter, remove the filter completely
fuzzyTextFilterFn.autoRemove = (val: any) => !val;
